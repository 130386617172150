import React, {useEffect, useState} from 'react'
import admin from '../../../../../assets/images/default.png'
import {
  UpdateuserbyidAction,
  GetUserById,
  DeleteProfilebyidAction,
} from '../../../../../Redux/Action/profileAction'
import {useForm} from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../../../../Redux/Action/locationAction'
import TimezoneSelect from 'react-timezone-select'
const Edit = () => {
  const studentid = localStorage.getItem('userid')
  const [file, setFile] = useState(null)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    clearErrors,
    watch,
  } = useForm({})

  const userdetails = useSelector((state) => {
    return state.login.getuserIdlist
  })

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  // useEffect(() => {
  //   setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().(userdetails.timeZone))
  // }, [])

  // useEffect(() => {
  //   if (userdetails.timeZone) {
  //     // Set the timezone directly as an object
  //     setSelectedTimezone({ value: userdetails.timeZone });
  //   }
  // }, [userdetails]);

  const formattedTimezone = selectedTimezone?.value || ''

  const [educationLevel, setEducationLevel] = useState('')
  const [PurposeOfTakingTheCourse, setPurposeOfTakingTheCourse] = useState('')
  const [EmploymentStatus, setEmploymentStatus] = useState('')
  const [HowGotToKnow, setHowGotToKnow] = useState('')

  const [educationLeveloptions, seteducationLevelOptions] = useState([
    'High-school',
    'University Degree',
    'Other',
  ])

  const [purposeOptions, setpurposeOption] = useState([
    'To enhance my Skills',
    'To learn about Microsoft Security',
    'To improve my chances of getting a job',
    'Other',
  ])

  const [employmentOption, setemploymentOption] = useState([
    'Unemployed',
    'Employed in a cyber security role',
    'Employed in a unrelated cyber security role',
    'Other',
  ])

  const [HowGotToKnowOption, setHowGotToKnowOption] = useState([
    'Word of mouth',
    'Referral',
    'Social Media',
    'Google',
    'Other',
  ])

  const [educationLevelExists, seteducationLevelExists] = useState(false)
  const [purposeExists, setPurposeExists] = useState(false)
  const [employmentExists, setemploymentExists] = useState(false)
  const [HowGotToKnowExists, setHowGotToKnowExists] = useState(false)

  useEffect(() => {
    const userEducationLevel = userdetails.message?.EducationLevel
    if (userEducationLevel) {
      const educationLevelExists = educationLeveloptions.some(
        (option) => option.toString() === userEducationLevel.toString()
      )
      seteducationLevelExists(educationLevelExists)
    }
  }, [userdetails.message?.EducationLevel, educationLeveloptions])

  useEffect(() => {
    const userPurpose = userdetails.message?.PurposeOfTakingTheCourse
    if (userPurpose) {
      const Purposeexists = purposeOptions.some(
        (option) => option.toString() === userPurpose.toString()
      )
      setPurposeExists(Purposeexists)
    }
  }, [userdetails.message?.PurposeOfTakingTheCourse, purposeOptions])

  useEffect(() => {
    const userEmploymentStatus = userdetails.message?.EmploymentStatus
    if (userEmploymentStatus) {
      const employmentexists = employmentOption.some(
        (option) => option.toString() === userEmploymentStatus.toString()
      )
      setemploymentExists(employmentexists)
    }
  }, [userdetails.message?.EmploymentStatus, employmentOption])

  useEffect(() => {
    const userHowYouGotToKnow = userdetails.message?.HowYouGotToKnow
    if (userHowYouGotToKnow) {
      const HowGotToKnowexists = HowGotToKnowOption.some(
        (option) => option.toString() === userHowYouGotToKnow.toString()
      )
      setHowGotToKnowExists(HowGotToKnowexists)
    }
  }, [userdetails.message?.HowYouGotToKnow, HowGotToKnowOption])

  const handleeducationLevelChange = (e) => {
    setEducationLevel(e.target.value)
  }

  const handlePurposeChange = (e) => {
    setPurposeOfTakingTheCourse(e.target.value)
  }

  const handleEmploymentChange = (e) => {
    setEmploymentStatus(e.target.value)
  }

  const handleHowGotToKnowChange = (e) => {
    setHowGotToKnow(e.target.value)
  }

  useEffect(() => {
    setValue('FirstName', userdetails.message && userdetails.message.FirstName)
    setValue('Surname', userdetails.message && userdetails.message.Surname)
    setValue('PhoneNumber', userdetails.message && userdetails.message.PhoneNumber)
    setValue('Country', userdetails.message && userdetails.message.Country)
    setValue('state', userdetails.message && userdetails.message.state)
    setValue('City', userdetails.message && userdetails.message.City)
    setValue('profileUrl', userdetails.message && userdetails.message.profileUrl)
    setValue('timezone', userdetails.message && userdetails.message.timezone)
    setValue('AgeBracket', userdetails.message && userdetails.message.AgeBracket)

    if (userdetails.message?.EducationLevel) {
      const educationLevelExists = educationLeveloptions.some(
        (option) => option.toString() == userdetails.message?.EducationLevel.toString()
      )

      if (educationLevelExists) {
        setEducationLevel(userdetails.message?.EducationLevel)
        setValue('EducationLevel', userdetails.message?.EducationLevel)
      } else {
        setEducationLevel('Other')
        setValue('OtherEducationLevel', userdetails.message?.EducationLevel)
        setValue('EducationLevel', 'Other')
      }
    }

    if (userdetails.message?.PurposeOfTakingTheCourse) {
      const purposeExists = purposeOptions.some(
        (option) => option.toString() == userdetails.message?.PurposeOfTakingTheCourse.toString()
      )

      if (purposeExists) {
        setPurposeOfTakingTheCourse(userdetails.message?.PurposeOfTakingTheCourse)
        setValue('PurposeOfTakingTheCourse', userdetails.message?.PurposeOfTakingTheCourse)
      } else {
        setPurposeOfTakingTheCourse('Other')
        setValue('OtherPurposeOfTakingTheCourse', userdetails.message?.PurposeOfTakingTheCourse)
        setValue('PurposeOfTakingTheCourse', 'Other')
      }
    }

    if (userdetails.message?.EmploymentStatus) {
      const employmentExists = employmentOption.some(
        (option) => option.toString() == userdetails.message?.EmploymentStatus.toString()
      )

      if (employmentExists) {
        setEmploymentStatus(userdetails.message?.EmploymentStatus)
        setValue('EmploymentStatus', userdetails.message?.EmploymentStatus)
      } else {
        setEmploymentStatus('Other')
        setValue('OtherEmploymentStatus', userdetails.message?.EmploymentStatus)
        setValue('EmploymentStatus', 'Other')
      }
    }

    if (userdetails.message?.HowYouGotToKnow) {
      const HowGotToKnowExists = HowGotToKnowOption.some(
        (option) => option.toString() == userdetails.message?.HowYouGotToKnow.toString()
      )

      if (HowGotToKnowExists) {
        setHowGotToKnow(userdetails.message?.HowYouGotToKnow)
        setValue('HowYouGotToKnow', userdetails.message?.HowYouGotToKnow)
      } else {
        setHowGotToKnow('Other')
        setValue('OtherHowYouGotToKnow', userdetails.message?.HowYouGotToKnow)
        setValue('HowYouGotToKnow', 'Other')
      }
    }

    setValue(
      'InterestedInCyberheadRole',
      userdetails.message && userdetails.message.InterestedInCyberheadRole
    )

    setValue(
      'YearsOfCyberSecurityExperience',
      userdetails.message && userdetails.message.YearsOfCyberSecurityExperience
    )
    setSelectedTimezone(
      userdetails.message?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
    )
  }, [userdetails])

  useEffect(() => {
    // debugger;
    dispatch(GetUserById(studentid))
  }, [dispatch, studentid])
  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState(
    userdetails.message && userdetails.message.Country
  )
  const [selectedStateISOCode, setSelectedStateISOCode] = useState(
    userdetails.message && userdetails.message.state
  )
  const [selectedCity, setSelectedCity] = useState(userdetails.message && userdetails.message.City)
  const onSubmit = (data) => {
    debugger
    const formData = new FormData()
    if (data.profileUrl && data.profileUrl[0]) {
      formData.append('profileUrl', data.profileUrl[0])
    }

    formData.append('FirstName', data.FirstName)
    formData.append('Surname', data.Surname)
    formData.append('PhoneNumber', data.PhoneNumber)
    formData.append('Country', data.Country)
    formData.append('state', selectedStateISOCode)
    formData.append('City', data.City)
    formData.append('AgeBracket', data.AgeBracket)
    formData.append('timezone', formattedTimezone || selectedTimezone)
    formData.append('YearsOfCyberSecurityExperience', data.YearsOfCyberSecurityExperience)

    if (educationLevel == 'Other') {
      formData.append('EducationLevel', data.OtherEducationLevel)
    } else {
      formData.append('EducationLevel', educationLevel)
    }

    if (PurposeOfTakingTheCourse == 'Other') {
      formData.append('PurposeOfTakingTheCourse', data.OtherPurposeOfTakingTheCourse)
    } else {
      formData.append('PurposeOfTakingTheCourse', PurposeOfTakingTheCourse)
    }

    if (EmploymentStatus == 'Other') {
      formData.append('EmploymentStatus', data.OtherEmploymentStatus)
    } else {
      formData.append('EmploymentStatus', EmploymentStatus)
    }

    if (HowGotToKnow == 'Other') {
      formData.append('HowYouGotToKnow', data.OtherHowYouGotToKnow)
    } else {
      formData.append('HowYouGotToKnow', HowGotToKnow)
    }

    formData.append('InterestedInCyberheadRole', data.InterestedInCyberheadRole)
    dispatch(UpdateuserbyidAction(formData, studentid, navigate))
  }

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  const handleChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCountryISOCode(selectedValue)
    setSelectedStateISOCode('')
    setSelectedCity('')
  }

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }

  useEffect(() => {
    if (userdetails.message) {
      setSelectedCountryISOCode(userdetails.message.Country)
      setSelectedStateISOCode(userdetails.message.state)
    }
  }, [userdetails.message])

  useEffect(() => {
    if (userdetails.message) {
      setSelectedCity(userdetails.message && userdetails.message.City)
    }
  }, [userdetails.message])

  useEffect(() => {
    setValue('City', selectedCity)
  }, [selectedCity, setValue])

  const handleCityChange = (event, formData) => {
    const selectedCityValue = event.target.value
    setSelectedCity(selectedCityValue)
    //formData.set('City', selectedCityValue)
  }

  const userid = localStorage.getItem('userid')

  const deleteHandler = () => {
    if (userid) {
      dispatch(DeleteProfilebyidAction(userid))
    } else {
      // console.error('User ID not found in local storage')
    }
  }
  const handleCancelClick = () => {
    window.location.reload()
  }

  const roleid = localStorage.getItem('roleid')
  return (
    <div
      className='user-editor'
      style={{
        marginTop: '47px',
      }}
    >
      <div className='card mb-xl-10 shadow-none'>
        <form
          id='kt_account_profile_details_form'
          className='form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='card-body border-top p-9'>
            <div className='row'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>Avatar</label>
              <div className='col-lg-8'>
                {/* Display current profile picture */}
                {userdetails.message?.profileUrl ? (
                  <div>
                    <img
                      src={userdetails.message.profileUrl}
                      alt='Current Profile'
                      style={{maxWidth: '100px'}}
                    />
                    <button onClick={deleteHandler} style={{marginLeft: '10px', border: '0px'}}>
                      Delete
                    </button>
                  </div>
                ) : (
                  <img src={admin} alt='Default Profile' style={{maxWidth: '100px'}} />
                )}

                <input
                  type='file'
                  className='form-control mt-10 w-50'
                  {...register('profileUrl', {})}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0]
                    setFile(URL.createObjectURL(selectedFile))
                  }}
                />
                {file && <img src={file} alt='Selected' style={{maxWidth: '100px'}} />}
                <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                {errors.profileUrl && (
                  <p className='alert_danger' role='alert'>
                    {errors.profileUrl.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>Full Name</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('FirstName', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.FirstName ? 'true' : 'false'}
                    />
                    {errors.FirstName && (
                      <p role='alert' className='alert_danger'>
                        {errors.FirstName?.message}
                      </p>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      name='fname'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='First name'
                      {...register('Surname', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.Surname ? 'true' : 'false'}
                    />
                    {errors.Surname && (
                      <p role='alert' className='alert_danger'>
                        {errors.Surname?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Telephone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  name='phone'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...register('PhoneNumber', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                />
                {errors.PhoneNumber && (
                  <p role='alert' className='alert_danger'>
                    {errors.PhoneNumber?.message}
                  </p>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Country</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  name='country'
                  aria-label='Select a Country'
                  data-control='select2'
                  data-placeholder='Select a country...'
                  className='form-select form-select-solid form-select-lg fw-semibold'
                  {...register('Country', {
                    required: 'This is required field',
                  })}
                  aria-invalid={errors.Country ? 'true' : 'false'}
                  value={selectedCountryISOCode}
                  onChange={handleChange}
                >
                  {errors.Country && (
                    <p className='alert_danger' role='alert'>
                      {errors.Country?.message}
                    </p>
                  )}
                  <option value=''>Select a Country...</option>
                  {Object.values(allCountries).map((countryArr) =>
                    countryArr.map((country, i) => (
                      <option key={i} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
            {state.count > 0 && (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>State/County</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='state'
                      aria-label='Select a Country'
                      data-control='select2'
                      data-placeholder='Select a country...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('state', {required: 'This field is required'})}
                      aria-invalid={errors.Country ? 'true' : 'false'}
                      value={selectedStateISOCode}
                      onChange={(e) => {
                        handleStateChange(e)
                        clearErrors('state') // Clear errors for the 'Country' field
                      }}
                    >
                      <option value=''>Select a State...</option>
                      {state.states.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <p className='alert_danger' role='alert'>
                        {errors.state?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span>City</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='City'
                      aria-label='Select a City'
                      data-control='select2'
                      data-placeholder='Select a City...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('City')}
                      aria-invalid={errors.City ? 'true' : 'false'}
                      value={selectedCity}
                      onChange={(e) => handleCityChange(e)}
                    >
                      {/* {errors.City && (
                      <p className='alert_danger' role='alert'>
                        {errors.City?.message}
                      </p>
                    )} */}
                      <option value=''>Select a City...</option>
                      {city.message &&
                        city.message.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </>
            )}
            {/* {city.count > 0 && (

            )} */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>Timezone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={setSelectedTimezone}
                  placeholder='Select Timezone'
                />
              </div>
            </div>

            {roleid == 3 ? (
              <>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Age</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      name='AgeBracket'
                      aria-label='Select a AgeBracket'
                      data-control='select2'
                      data-placeholder='Select a AgeBracket...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('AgeBracket', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.AgeBracket ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('AgeBracket')
                      }}
                    >
                      <option value=''>Select</option>
                      <option value='18-25'>18-25</option>
                      <option value='26-30'>26-30</option>
                      <option value='31-35'>31-35</option>
                      <option value='36-40'>36-40</option>
                      <option value='40+'>40+</option>
                    </select>
                    {errors.AgeBracket && (
                      <p className='alert_danger' role='alert'>
                        {errors.AgeBracket?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Years of Cyber Security Experience</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a YearsOfCyberSecurityExperience...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('YearsOfCyberSecurityExperience', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.YearsOfCyberSecurityExperience ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('YearsOfCyberSecurityExperience')
                      }}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='None' style={{color: '#495057'}}>
                        None{' '}
                      </option>
                      <option value='1-2'>1-2</option>
                      <option value='3-5'>3-5</option>
                      <option value='5-7'>5-7</option>
                      <option value='7+'>7+</option>
                    </select>
                    {errors.YearsOfCyberSecurityExperience && (
                      <p className='alert_danger' role='alert'>
                        {errors.YearsOfCyberSecurityExperience?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Education Level</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <select
                      name='EducationLevel'
                      aria-label='Select an EducationLevel'
                      data-control='select2'
                      data-placeholder='Select an EducationLevel...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('EducationLevel', {
                        required: 'This is a required field',
                      })}
                      aria-invalid={errors.EducationLevel ? 'true' : 'false'}
                      value={educationLevel}
                      onChange={handleeducationLevelChange}
                    >
                      <option value=''>Select Education Level</option>
                      {educationLeveloptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors.EducationLevel && (
                      <p className='alert_danger' role='alert'>
                        {errors.EducationLevel?.message}
                      </p>
                    )}
                    {educationLevel === 'Other' ? (
                      <input
                        type='text'
                        placeholder='Please specify your education level'
                        className='form-control'
                        {...register('OtherEducationLevel', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherEducationLevel ? 'true' : 'false'}
                      />
                    ) : (
                      <></>
                    )}
                    {errors.OtherEducationLevel && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherEducationLevel?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Purpose of taking the Course</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a City...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('PurposeOfTakingTheCourse', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                      value={PurposeOfTakingTheCourse}
                      onChange={handlePurposeChange}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select
                      </option>
                      {purposeOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors.PurposeOfTakingTheCourse && (
                      <p className='alert_danger' role='alert'>
                        {errors.PurposeOfTakingTheCourse?.message}
                      </p>
                    )}

                    {PurposeOfTakingTheCourse === 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify your purpose'
                        className='form-control'
                        {...register('OtherPurposeOfTakingTheCourse', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherPurposeOfTakingTheCourse ? 'true' : 'false'}
                      />
                    )}

                    {errors.OtherPurposeOfTakingTheCourse && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherPurposeOfTakingTheCourse?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Interested In Cyberhead Role</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a InterestedInCyberheadRole...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('InterestedInCyberheadRole', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                      onChange={() => {
                        clearErrors('InterestedInCyberheadRole')
                      }}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      <option value='Yes' style={{color: '#495057'}}>
                        Yes{' '}
                      </option>
                      <option value='No'>No</option>
                    </select>
                    {errors.InterestedInCyberheadRole && (
                      <p className='alert_danger' role='alert'>
                        {errors.InterestedInCyberheadRole?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>Current employment status</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a EmploymentStatus...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('EmploymentStatus', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                      value={EmploymentStatus}
                      onChange={handleEmploymentChange}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      {employmentOption.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors.EmploymentStatus && (
                      <p className='alert_danger' role='alert'>
                        {errors.EmploymentStatus?.message}
                      </p>
                    )}
                    {EmploymentStatus === 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify your employment status'
                        className='form-control'
                        {...register('OtherEmploymentStatus', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherEmploymentStatus ? 'true' : 'false'}
                      />
                    )}
                    {errors.OtherEmploymentStatus && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherEmploymentStatus?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                    <span className='required'>How did you find out about Cyberheads?</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      id='inputState'
                      data-control='select2'
                      data-placeholder='Select a HowYouGotToKnow...'
                      className='form-select form-select-solid form-select-lg fw-semibold'
                      {...register('HowYouGotToKnow', {
                        required: 'This is required field',
                      })}
                      aria-invalid={errors.dayRate ? 'true' : 'false'}
                      value={HowGotToKnow}
                      onChange={handleHowGotToKnowChange}
                    >
                      <option value='' disabled selected style={{color: '#495057'}}>
                        Select{' '}
                      </option>
                      {HowGotToKnowOption.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors.HowYouGotToKnow && (
                      <p className='alert_danger' role='alert'>
                        {errors.HowYouGotToKnow?.message}
                      </p>
                    )}
                    {HowGotToKnow === 'Other' && (
                      <input
                        type='text'
                        placeholder='Please specify how you found out about Cyberheads'
                        className='form-control'
                        {...register('OtherHowYouGotToKnow', {
                          required: 'This is a required field',
                        })}
                        aria-invalid={errors.OtherHowYouGotToKnow ? 'true' : 'false'}
                      />
                    )}
                    {errors.OtherHowYouGotToKnow && (
                      <p className='alert_danger' role='alert'>
                        {errors.OtherHowYouGotToKnow?.message}
                      </p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div></div>
            )}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Link to=''>
              <button
                id='kt_password_cancel'
                type='button'
                className='btn cybercancelbtn me-2'
                style={{
                  backgroundColor: 'white',
                }}
                onClick={handleCancelClick}
              >
                Cancel
              </button>
            </Link>
            <button
              type='submit'
              className='btn cyberbtnallpage'
              id='kt_account_profile_details_submit'
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Edit
