import axios from 'axios'
import {
  getAllCoursesslice,
  getAllBundlesslice,
  getTopCoursesslice,
  getRecentCoursesslice,
  getRecentSevenDaysCoursesslice,
  getRecentMonthlyCoursesslice,
  getusersByLocationslice,
  getRecentslice,
  getyesterdayRecentslice,
  getAllUserCountslice,
  getThisMonthTopCoursesslice,
  getCountryFromISOslice,
  getMapStateCountryslice,
  getLastSixMonthTopCoursesslice,
  getLastMonthTopCoursesslice,
  getBundleSalesslice,
  MaxRegistrationslice,
  getMonthlySalesSlice,
  getTopPurchasedCoursesSlice
} from '../Slice/authSlice'

export const AllCoursesCount = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/coursesCount`

    const res = await axios.get(getuserid, config)

    await dispatch(getAllCoursesslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const BundlesCount = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getBundlesCount`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getAllBundlesslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const TopCoursesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/topCourses`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getTopCoursesslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const RecentCoursesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/topRecentCourses`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getRecentCoursesslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const RecentSevenDaysCoursesAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/recentSevenDays`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getRecentSevenDaysCoursesslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const RecentMonthlyCoursesAction = () => async (dispatch) => {
  try {
    
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/monthlyCoursePurchases`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getRecentMonthlyCoursesslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const todayRecentAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/todayRecentAdmin`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getRecentslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const yesterdayRecentAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/yesterdayRecentCourses`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getyesterdayRecentslice(res))
  } catch (e) {
    // console.log(e);
  }
}

export const GetAllUserCount = () => async (dispatch) => {
  // debugger;
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getUsersCount`

    const res = await axios.get(getuserid, config)

    await dispatch(getAllUserCountslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const thisMonthTopCourses = () => async (dispatch) => {
  // debugger
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/topCoursesOfThisMonth`

    const res = await axios.get(getuserid, config, '')
    // console.log(res, 'resres')
    await dispatch(getThisMonthTopCoursesslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const LastMonthTopCourses = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/topCoursesOfLastMonth`

    const res = await axios.get(getuserid, config, '')
    // console.log(res, 'resres')
    await dispatch(getLastMonthTopCoursesslice(res))
  } catch (e) {
    console.log(e)
  }
}

export const LastSixMonthTopCourses = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/lastSixMonthTopCourses`

    const res = await axios.get(getuserid, config, '')
    // console.log(res, 'resres')
    await dispatch(getLastSixMonthTopCoursesslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const mapStateByCountry = (selectState) => async (dispatch) => {
  // debugger;
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getStatesOfCountry/${selectState}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getMapStateCountryslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const allCountryUsers = () => async (dispatch) => {
  // debugger
  try {
    // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/getCountryFromISO`

    const res = await axios.get(getuserid, config, '')
    // console.log(res, 'resres')
    await dispatch(getCountryFromISOslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const usersByLocationAction = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/countryCountUsers`

    const res = await axios.get(getuserid, config, '')

    await dispatch(getusersByLocationslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const GetAllBundleSalesCount = () => async (dispatch) => {
  // debugger;
  try {
    // debugger;
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/BundleSales`

    const res = await axios.get(getuserid, config)

    await dispatch(getBundleSalesslice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const getMonthlySales = (selectRange, startDate, endDate) => async (dispatch) => {
  //debugger
  try {
   // debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getsales = `${process.env.REACT_APP_BACKEND_API}/monthlySalesFilter?type=${selectRange}&startDate=${startDate}&endDate=${endDate}`

    const res = await axios.get(getsales, config)

    await dispatch(getMonthlySalesSlice(res))
  } catch (e) {
    // console.log(e)
  }
}

export const MaxRegistrationAction = (country) => async (dispatch) => {
  // debugger
  try {
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getuserid = `${process.env.REACT_APP_BACKEND_API}/registration_filter/${country}`

    const res = await axios.get(getuserid, config, '')

    await dispatch(MaxRegistrationslice(res))
  } catch (e) {
    // console.log(e);
  }
}



export const getTopPurchasedCourses = (selectRange) => async (dispatch) => {
  // debugger
  try {
  //  debugger
    const token = localStorage.getItem('authToken')

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    const getsales = `${process.env.REACT_APP_BACKEND_API}/topPurchasedCourses?type=${selectRange}`

    const res = await axios.get(getsales, config)

    await dispatch(getTopPurchasedCoursesSlice(res))
  } catch (e) {
    // console.log(e)
  }
}
