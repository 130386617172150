import {loadStripe} from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'

import {CheckoutForm} from './CheckoutForm'

const key = "pk_test_51Q08jBP4cuEWV1gxAqkKYFSvNjHvccS45mDIBEsAmNZiP2OsNtzE1RmEqpx3FZ8QJvjlk9INMn8Py37eb4sG0Rcj00VJbu7sCA"

const stripeTestPromise = loadStripe(key)

const Stripe = ({setCardComplete}) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm setCardComplete={setCardComplete} />
    </Elements>
  )
}

export default Stripe
